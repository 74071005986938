export const masterConfigProperties = {
  lastName: "Default",
  googleSheetUrl: "https://docs.google.com/spreadsheets/d/1d28w0Trn2ST-sg7VvIeNZwzBSSth-e3z_-HYmojyKCc/edit?usp=sharing",
  googleFormUrl : "https://goo.gl/forms/d9M8EAgyg5UC1A4z2",
  amountToCharge: 300
};
export const firebaseConfig = {
    apiKey: "AIzaSyBRdAV6b5Yno1mFUhS71b_j--9sMX9UzUU",
    authDomain: "dragglesimulator.firebaseapp.com",
    databaseURL: "https://dragglesimulator.firebaseio.com",
    projectId: "dragglesimulator",
    storageBucket: "dragglesimulator.appspot.com",
    messagingSenderId: "1048508881173",
    appId: "1:1048508881173:web:0dbdb82c9a912d0f47f11c",
    measurementId: "G-XQZHWQNF41"
  };
