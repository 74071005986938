import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mated-snackbar',
  templateUrl: './mated-snackbar.component.html',
  styleUrls: ['./mated-snackbar.component.css']
})
export class MatedSnackbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
